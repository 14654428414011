import { library } from '@fortawesome/fontawesome-svg-core';
import { faInstagram, faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { far, faHandPointRight, faHandPointDown, faWindowClose, } from '@fortawesome/free-regular-svg-icons'
import { faCheckSquare, faCoffee, faAngleLeft, faAngleRight,
    faHeart, faCircle, faClock, faHourglassHalf, faReply, faTrashAlt,
    faCheck, faPlus, faMinus, faShoppingBasket, faTimes, faMapMarkerAlt,
    // faWindowClose,
} from '@fortawesome/free-solid-svg-icons';

library.add(faInstagram, faCcMastercard, faCcVisa, far, faCheckSquare, faCoffee, faAngleLeft,
    faAngleRight, faHeart, faCircle, faClock, faHourglassHalf, faReply, faTrashAlt,
    faCheck, faPlus, faMinus, faShoppingBasket, faTimes, faMapMarkerAlt,
    faHandPointRight, faHandPointDown, faWindowClose
);
