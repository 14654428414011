import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekDay from 'dayjs/plugin/weekday';

import fr from 'dayjs/locale/fr';

dayjs.extend(weekOfYear);
dayjs.extend(weekDay)
// week start on monday
/*
dayjs.locale({
    ...fr,
    weekStart: 1,
});
*/
dayjs.locale('fr')

export const getDate = () => {
    const day = dayjs();
    return day;
};

export const getNextWeek = (date) => dayjs(date).add(7, 'day');

export const getPreviousWeek = (date) => dayjs(date).subtract(7, 'day');

const getNextOrderableWeek = (today = dayjs()) => {
    const orderableWeek = today.add(1, 'week').week();
    return orderableWeek;
};

export const weekIdToDate = (weekId) => {
    const [year, week] = weekId.split('-').map(s => parseInt(s, 10));
    return dayjs().year(year).week(week)
        // .set('day', 5)
        // use Day of Week (Locale Aware)
        .weekday(4)
        .format('dddd D MMMM')
        .toLowerCase();
};

export const weekIdToShortDate = (weekId) => {
    const [year, week] = weekId.split('-').map(s => parseInt(s, 10));
    return dayjs().year(year).week(week)
        // .set('day', 5)
        // use Day of Week (Locale Aware)
        .weekday(4)
        .format('DD/MM')
        .toLowerCase();
};

export const getNextFriday = (date = dayjs()) => {
    const orderableWeek_day = date.add(1, 'week');
    const currentYear = date.year();
    const nextDayYear = orderableWeek_day.year();

    if (currentYear < nextDayYear) {
        return orderableWeek_day.weekday(4)
            .format('dddd D MMMM')
            .toLowerCase();
    }

    return date.week(orderableWeek_day.week())
        // .set('day', 5)
        // use Day of Week (Locale Aware)
        .weekday(4)
        .format('dddd D MMMM')
        .toLowerCase();
};


// TODO: !!! cette fonction utilise la date du jour et set la semaine,
// du coup l'année n'est pas bonne, et le vendredi suivant non plus
export const getNextFridayNumber = (date = dayjs()) => {
    const orderableWeek_day = date.add(1, 'week');
    const currentYear = date.year();
    const nextDayYear = orderableWeek_day.year();

    if (currentYear < nextDayYear) {
        return orderableWeek_day.weekday(4)
            .format('D MMMM')
            .toLowerCase();
    }

    return date.week(orderableWeek_day.week())
        // .set('day', 5)
        // use Day of Week (Locale Aware)
        .weekday(4)
        .format('D MMMM')
        .toLowerCase();
};

export const getNextOrderableWeekToken = (today = dayjs()) => {
    const orderableWeek = today.add(1, 'week');
    const weekId = orderableWeek.week().toString();
    return `${orderableWeek.year()}-${weekId.padStart(2, '0')}`
};
