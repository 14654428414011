// import firebase from 'firebase';
/*
import app from 'firebase/app';
// import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
*/
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const config = {
    apiKey: process.env.GATSBY_FIREBASE_APIKEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_FIREBASE_APP_ID,
    measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
};
// firebase.initializeApp(config);

/* development
var firebaseConfig = {
    apiKey: "AIzaSyBCkcRjeorWmQUHBKXwL_qDHCcuHPwS_Mo",
    authDomain: "cabadici-dev.firebaseapp.com",
    databaseURL: "https://cabadici-dev.firebaseio.com",
    projectId: "cabadici-dev",
    storageBucket: "cabadici-dev.appspot.com",
    messagingSenderId: "390550870749",
    appId: "1:390550870749:web:2e0e8ad507f0710e461934",
    measurementId: "G-CJT5NE07ES"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  */

class Firebase {
    constructor() {
        // save local instance of for callng functions from specific region.
        this.app = app.initializeApp(config);

        if (typeof window !== 'undefined') {
            this.auth = app.auth()
            this.auth.setPersistence(app.auth.Auth.Persistence.NONE); // SESSION // LOCAL
            /*
            this.auth.onAuthStateChanged(user => {
                if (user) {
                    alert(JSON.stringify(user))// User is signed in.
                }
            });
            */
            this.db = app.firestore();
            this.db.settings({
                // timestampsInSnapshots: true, => default setting
            });
            this.firestore = app.firestore;

            // Initialize Cloud Functions through Firebase
            // this.functions = app.functions;
            this.functions = this.app.functions('europe-west2');
        }
    }

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    createApiOrder = order => {
        const userRef = this.user(this.auth.currentUser.uid);
        return this.db.collection('orders').add({...order, userRef});
    };

    createApiSubscription = subscription => {
        const userRef = this.user(this.auth.currentUser.uid);
        return this.db.collection('subscriptions').add({...subscription, userRef});
    };

    // *** User API ***

    user = uid => this.db.doc(`users/${uid}`);

    users = () => this.db.collection('users');

    /** Listeners
     */
    onAuthUserListener = (next, fallback) => {
        this.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                this.user(authUser.uid)
                    .get()
                    .then(snapshot => {
                        const dbUser = snapshot.data();

                        // default empty roles
                        if (!dbUser.roles) {
                            dbUser.roles = {};
                        }

                        // merge auth and db user
                        next({
                            uid: authUser.uid,
                            email: authUser.email,
                            emailVerified: authUser.emailVerified,
                            providerData: authUser.providerData,
                            ...dbUser,
                        });
                    });
            } else {
                fallback();
            }
        });
    };
}

export default Firebase;
