import api from '../services/api';
import {
    signIn,
    createApiUser,
    resetPassword,
    createApiOrder,
    createApiSubscription,
    createDbUser,
    fetchDbUser,
    updateApiUser,
    updateSubscriptionStatus,
    updateSubscriptionStatusRemoveWeekId,
    updateSubscriptionDeactivate,
    updateSubscriptionReactivate,
    getUser,
    signOutFromApi,
    updateApiOrder,
    deleteApiOrderById,
} from '../services';
import translateError from '../services/translateError';

export const UPDATE_RECIPES_NUMBER = 'UPDATE_RECIPES_NUMBER';
export const UPDATE_SUBSCRIPTION_CHOICE = 'UPDATE_SUBSCRIPTION_CHOICE';
export const ADD_RECIPE_TO_SELECTION = 'ADD_RECIPE_TO_SELECTION';
export const REMOVE_RECIPE_FROM_SELECTION = 'REMOVE_RECIPE_FROM_SELECTION';
export const UPDATE_DELIVERY_HOURS_CHOICE = 'UPDATE_DELIVERY_HOURS_CHOICE';
export const UPDATE_DELIVERY_CHOICE = 'UPDATE_DELIVERY_CHOICE';
export const UPDATE_PRIVATE_DELIVERY_ADDRESS = 'UPDATE_PRIVATE_DELIVERY_ADDRESS';
export const UPDATE_PARTNER_DELIVERY_ADDRESS = 'UPDATE_PARTNER_DELIVERY_ADDRESS';
export const SIGNIN_ERROR = 'SIGNIN_ERROR';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const ADD_ITEM_TO_SELECTION = 'ADD_ITEM_TO_SELECTION';
export const REMOVE_ITEM_FROM_SELECTION = 'REMOVE_ITEM_FROM_SELECTION';
export const UPDATE_ITEM_FROM_SELECTION = 'UPDATE_ITEM_FROM_SELECTION';
export const CLEAN_ERROR = 'CLEAN_ERROR';
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const LOGIN_USER = 'LOGIN_USER';
export const USER_DATA = 'USER_DATA';
export const AUTH_USER_DATA = 'AUTH_USER_DATA';
export const UPDATE_ORDER_FROM_API = 'UPDATE_ORDER_FROM_API';

export const updateOrderFromApi = order => async dispatch => {
    dispatch({
        type: UPDATE_ORDER_FROM_API,
        value: order,
    });
};

export const updatePrivateDeliveryAddress = deliveryAddress => ({
    type: UPDATE_PRIVATE_DELIVERY_ADDRESS,
    value: deliveryAddress
});

export const updatePartnerDeliveryAddress = deliveryAddress => ({
    type: UPDATE_PARTNER_DELIVERY_ADDRESS,
    value: deliveryAddress
});

export const addItemToSelection = item => ({
    type: ADD_ITEM_TO_SELECTION,
    value: item
});

export const removeItemFromSelection = item => ({
    type: REMOVE_ITEM_FROM_SELECTION,
    value: item
});

export const updateItemFromSelection = item => ({
    type: UPDATE_ITEM_FROM_SELECTION,
    value: item
});

export const cleanError = () => ({
    type: CLEAN_ERROR
});

export const updateUSerData = userData => async dispatch => {
    dispatch({
        type: USER_DATA,
        value: userData,
    });
};

export const updateAuthUserData = authUserData => async dispatch => {
    dispatch({
        type: AUTH_USER_DATA,
        value: authUserData,
    });
};

export const forceConnexion = () => async dispatch => {
    dispatch({
        type: LOG_IN,
        value: getUser().uid,
    });
}

export const signInUser = (values) => async dispatch => {
    return signIn(values)
        .then(({user}) => {
            dispatch({
                type: LOG_IN,
                value: user.uid,
            });
            return fetchDbUser(user.uid)
                .then(userData => {
                    dispatch({
                        type: USER_DATA,
                        value: userData,
                    });
                })
                .then(() => {
                    return Promise.resolve();
                })
        })
        .catch(error => {
            const frenchError = translateError(error);
            dispatch({
                type: SIGNIN_ERROR,
                value: frenchError,
            })
        });
}

export const updateOrder = (values) => async dispatch => {
    return updateApiOrder(values)
        .then(resp => {
            // this.setState({ ...INITIAL_STATE });
            return resp
        });
};

export const createOrder = (values) => async dispatch => {
    return createApiOrder(values)
        .then(resp => {
            // this.setState({ ...INITIAL_STATE });
            return resp
        });
};

export const deleteOrderById = (value) => async dispatch => {
    return deleteApiOrderById(value)
        .then(resp => {
            dispatch({
                type: UPDATE_ORDER_FROM_API,
                value: null,
            });
            return resp
        });
};

export const createSubscription = () => async dispatch => {
    return createApiSubscription();
};

export const createUser = ({ email, password, ...userData}) => async dispatch => {
    console.log(userData);
    return createApiUser({ email, password })
        .then(authUser => {
            console.log(authUser);
            /*
            dispatch({
                type: LOG_IN,
                value: authUser.uid,
            })
            */
            dispatch({
                type: USER_DATA,
                value: {email, ...userData},
            });
            return createDbUser({ authUser, email, ...userData })
        })
};

export const updateUserProfil = (values) => dispatch => {
    return updateApiUser(values);
};

export const createUserError = (error) => async dispatch => {
    const frenchError = translateError(error);
    dispatch({
        type: CREATE_USER_ERROR,
        value: frenchError,
    })
};

export const resetUserPassword = (value) => async dispatch => {
    resetPassword(value)
        .then(authUser => {
            alert('ok')
            console.log(authUser);
            // this.setState({ ...INITIAL_STATE });
        })
        .catch(error => {
            const frenchError = translateError(error);
            dispatch({
                type: RESET_PASSWORD_ERROR,
                value: frenchError,
            })
        });
}

export const subscriptionDeactivateCurrentWeekOrder = (weekId) => async dispatch => {
    return updateSubscriptionStatus(weekId);
}

export const subscriptionReactivateCurrentWeekOrder = (weekId) => async dispatch => {
    return updateSubscriptionStatusRemoveWeekId(weekId);
}

export const subscriptionDeactivate = () => async dispatch => {
    return updateSubscriptionDeactivate();
}

export const subscriptionReactivate = () => async dispatch => {
    return updateSubscriptionReactivate();
}

export const connexion = () => async dispatch => {
    dispatch({
        type: 'LOGGED',
    });
}

export const deconnexion = () => async dispatch => {
    dispatch({
        type: 'LOG_OUT',
    });
}

export const signOut = () => async dispatch => {
    signOutFromApi().then(() => {
        // clean data
        dispatch({
            type: LOG_OUT,
        });
    })
}


/*
1. l'user se connecte
1.x:
    - erreur
    TODO
2.a si il est client:
    - on recup ses orders
2.b si il n'est pas client:
    - on passe [] à orders
3. on met à jour le state
*/

const connectAction = (user, pwd) => async dispatch => {
    console.log(user, pwd);
    let userA = await api.login(user, pwd);
    console.log(userA);
    /*
    dispatch({
        type: 'LOGGED',
        info: userA.user
    })
    */

    if (userA.user.client) {
        const orders = await api.getEntries('orders', {
            client: userA.user.client,
        });
        console.log(orders);
        /*
        dispatch({
            type: 'CONNECT'
        });
        dispatch({
            type: 'ORDERS',
            orders
        });
        */
        dispatch({
            type: 'LOGGED and ORDERS',
            info: userA.user,
            orders,
        });
    } else {
        /*
        dispatch({
            type: 'CONNECT'
        });
        */
        dispatch({
            type: 'LOGGED',
            info: userA.user,
        });
    }
};
const connectActionNoStore = async (user, pwd) => {
    let userA = await api.login(user, pwd);
    return true;
};

const connectByToken = token => async dispatch => {
    api.setToken(token, true);
    let userA = await api.request('get', '/users/me');
    if (userA.client) {
        const orders = await api.getEntries('orders', { client: userA.client });
        console.log(orders);
        /*
        dispatch({
            type: 'CONNECT'
        });
        dispatch({
            type: 'ORDERS',
            orders
        });
        */
        dispatch({
            type: 'LOGGED and ORDERS',
            info: userA,
            orders,
        });
    } else {
        /*
        dispatch({
            type: 'CONNECT'
        });
        */
        dispatch({
            type: 'LOGGED',
            info: userA,
        });
    }
};

const updateRecipes = (week, month) => async dispatch => {
    const recipes = await api.getEntries('recipes', { semaine: week });
    const [recipe] = await api.getEntries('recipes', {
        mois: month + 1,
        status: 'mensuel',
    });
    console.log('recipes', recipes);

    /*
    dispatch({
        type: 'WEEK_RECIPES',
        recipes
    });
    */

    dispatch({
        type: 'WEEK_RECIPES and MONTH_RECIPE',
        WeekRecipes: recipes,
        MonthRecipe: recipe,
    });
};

const getRecipes = async (week, month) => {
    const recipes = await api.getEntries('recipes', { semaine: week });
    const [recipe] = await api.getEntries('recipes', {
        mois: month + 1,
        status: 'mensuel',
    });

    return {
        WeekRecipes: recipes,
        MonthRecipe: recipe,
    };
};

const createUserAction = props => async dispatch => {
    let { name: user, email, password: pwd } = props;
    let userA = await api.register(user, email, pwd);
    dispatch({
        type: 'NEWUSER',
        info: userA.user,
    });
    dispatch({
        type: 'LOGGED',
        info: userA.user,
    });
    const orders = await api.getEntries('orders');
    console.log(orders);
    dispatch({
        type: 'CONNECT',
    });
    dispatch({
        type: 'ORDERS',
        orders,
    });
};

const createUserActionNoStore = async props =>  {
    let { name: user, email, password: pwd } = props;
    let userA = await api.register(user, email, pwd);
    return true;
};

/*
export {
    connectAction,
    connectActionNoStore,
    connectByToken,
    createUserAction,
    createUserActionNoStore,
    updateRecipes,
    getRecipes,
};
*/
