// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-cgv-js": () => import("../src/pages/cgv.js" /* webpackChunkName: "component---src-pages-cgv-js" */),
  "component---src-pages-concept-js": () => import("../src/pages/concept.js" /* webpackChunkName: "component---src-pages-concept-js" */),
  "component---src-pages-epicerie-js": () => import("../src/pages/epicerie.js" /* webpackChunkName: "component---src-pages-epicerie-js" */),
  "component---src-pages-faq-js": () => import("../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-ici-ou-js": () => import("../src/pages/ici-ou.js" /* webpackChunkName: "component---src-pages-ici-ou-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-livraisons-js": () => import("../src/pages/livraisons.js" /* webpackChunkName: "component---src-pages-livraisons-js" */),
  "component---src-pages-mentions-legales-js": () => import("../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-recettes-js": () => import("../src/pages/recettes.js" /* webpackChunkName: "component---src-pages-recettes-js" */),
  "component---src-pages-user-cabadici-js": () => import("../src/pages/user/cabadici.js" /* webpackChunkName: "component---src-pages-user-cabadici-js" */),
  "component---src-pages-user-index-js": () => import("../src/pages/user/index.js" /* webpackChunkName: "component---src-pages-user-index-js" */),
  "component---src-pages-user-informations-js": () => import("../src/pages/user/informations.js" /* webpackChunkName: "component---src-pages-user-informations-js" */),
  "component---src-pages-user-orders-js": () => import("../src/pages/user/orders.js" /* webpackChunkName: "component---src-pages-user-orders-js" */)
}

