import Strapi from 'strapi-sdk-javascript';

const strapi = new Strapi(process.env.GATSBY_CABADICI_API);

// const strapi = new Strapi('http://localhost:1337');

const api = {
    login: strapi.login,
    register: strapi.register,
    getEntries: strapi.getEntries,
};

// doesnr work : this.clearToken is not a function
// export default api;

export default strapi;
