import { combineReducers } from 'redux';
import {
    UPDATE_RECIPES_NUMBER,
    UPDATE_SUBSCRIPTION_CHOICE,
    ADD_RECIPE_TO_SELECTION,
    REMOVE_RECIPE_FROM_SELECTION,
    UPDATE_DELIVERY_HOURS_CHOICE,
    UPDATE_DELIVERY_CHOICE,
    UPDATE_PRIVATE_DELIVERY_ADDRESS,
    UPDATE_PARTNER_DELIVERY_ADDRESS,
    SIGNIN_ERROR,
    CREATE_USER_ERROR,
    RESET_PASSWORD_ERROR,
    ADD_ITEM_TO_SELECTION,
    REMOVE_ITEM_FROM_SELECTION,
    UPDATE_ITEM_FROM_SELECTION,
    CLEAN_ERROR,
    LOG_IN,
    USER_DATA,
    LOG_OUT,
    AUTH_USER_DATA,
    UPDATE_ORDER_FROM_API
} from '../actions';

const isLoggedOld = (state = false, action) => {
    switch (action.type) {
        case 'LOGOUT':
            return false;
        case 'LOGGED':
        console.log('TRY TO LOGGGGGIN');
            return true;
        default:
            return state;
    }
};

const isLogged = (state = false, action) => {
    switch (action.type) {
    case LOG_OUT:
        return false;
    case LOG_IN:
        return true;
    default:
        return state;
    }
};

const recipesNumber = (state = 3, action) => {
    switch (action.type) {
    case UPDATE_RECIPES_NUMBER:
        return action.value;
    case UPDATE_ORDER_FROM_API:
        if (action.value === null) {
            return 3;
        }
        return action.value.recipes.recipesNumber;
    default:
        return state;
    }
};

const subscriptionChoice = (state = 0, action) => {
    switch (action.type) {
    case UPDATE_SUBSCRIPTION_CHOICE:
        return action.value;
    default:
        return state;
    }
};

const deliveryHoursChoice = (state = 0, action) => {
    switch (action.type) {
    case UPDATE_DELIVERY_HOURS_CHOICE:
        return action.value;
    case UPDATE_ORDER_FROM_API:
        if (action.value === null) {
            return 0;
        }
        return action.value.delivery.deliveryHoursChoice;
    default:
        return state;
    }
};

const deliveryChoice = (state = 3, action) => {
    switch (action.type) {
    case UPDATE_DELIVERY_CHOICE:
        return action.value;
    case UPDATE_ORDER_FROM_API:
        if (action.value === null) {
            return 3;
        }
        return action.value.delivery.deliveryChoice;
    default:
        return state;
    }
};

const privateDeliveryAddress = (state = null, action) => {
    switch (action.type) {
    case UPDATE_PRIVATE_DELIVERY_ADDRESS:
        return action.value;
    case UPDATE_PARTNER_DELIVERY_ADDRESS:
        return null;
    case UPDATE_ORDER_FROM_API:
        if (action.value === null) {
            return null;
        }
        return action.value.delivery.privateDeliveryAddress;
    default:
        return state;
    }
};

const partnerDeliveryAddress = (state = null, action) => {
    switch (action.type) {
    case UPDATE_PARTNER_DELIVERY_ADDRESS:
        return action.value;
    case UPDATE_PRIVATE_DELIVERY_ADDRESS:
        return null;
    case UPDATE_ORDER_FROM_API:
        if (action.value === null) {
            return null;
        }
        return action.value.delivery.partnerDeliveryAddress;
    default:
        return state;
    }
};

const user = (state = {}, action) => {
    switch (action.type) {
    case USER_DATA:
        return action.value;
    case LOG_OUT:
        return {};
    default:
        return state;
    }
};

const error = (state = null, action) => {
    switch (action.type) {
    case SIGNIN_ERROR:
        return action.value;
    case RESET_PASSWORD_ERROR:
        return action.value;
    case CREATE_USER_ERROR:
        return action.value;
    case CLEAN_ERROR:
        console.log('CLEAN_ERROR');
        return null;
    default:
        return state;
    }
};

const recipesSelection = (state = [], action) => {
    switch (action.type) {
    case ADD_RECIPE_TO_SELECTION:
        return [action.value, ...state];
    case REMOVE_RECIPE_FROM_SELECTION:
        return [...state.filter(s => s.id !== action.value.id)];
    case UPDATE_ORDER_FROM_API:
        if (action.value === null) {
            return [];
        }
        return action.value.recipes.recipesSelection;
    default:
        return state;
    }
};

const itemsSelection = (state = [], action) => {
    switch (action.type) {
    case ADD_ITEM_TO_SELECTION:
        return [action.value, ...state];
    case REMOVE_ITEM_FROM_SELECTION:
        return [...state.filter(s => s.id !== action.value)];
    case UPDATE_ITEM_FROM_SELECTION:
        return [...state.filter(s => s.id !== action.value.id), action.value]
    case UPDATE_ORDER_FROM_API:
        if (action.value === null) {
            return [];
        }
        return action.value.grocery.itemsSelection;
    default:
        return state;
    }
};

const userUid = (state = null, action) => {
    switch (action.type) {
    case LOG_IN:
        return action.value;
    case LOG_OUT:
        return null;
    default:
        return state;
    }
};

const authUserData = (state = {}, action) => {
    switch (action.type) {
    case AUTH_USER_DATA:
        return action.value;
    case LOG_OUT:
        return {};
    default:
        return state;
    }
};

export const reducers = combineReducers({
    isLogged,
    recipesNumber,
    subscriptionChoice,
    recipesSelection,
    deliveryHoursChoice,
    deliveryChoice,
    privateDeliveryAddress,
    partnerDeliveryAddress,
    error,
    itemsSelection,
    userUid,
    user,
    authUserData,
});
