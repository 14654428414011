export default ({ code, message }) => {
    switch (code) {
    case 'auth/email-already-in-use':
        return {
            code,
            message: `Cette adresse email a déjà été enregistrée sur nos services.
            Essayez de vous connecter en utilisant le mot de passe associé,
            ou veuillez utiliser une autre adresse email.`
        };
    case 'auth/user-not-found':
    case 'auth/wrong-password':
        return {
            code,
            message: `L'adresse email ou le mot de passe utilisés ne sont pas valides.`
        };
    default:
        return {
            code,
            message
        };
    }
}
