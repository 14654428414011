import Firebase from './firestore';
import { getDate, getWeekId } from './dates';

const firebase = new Firebase();

export const getMessage = async () => {
    const toDay = new Date();
    const result = await firebase.db.collection('messages')
        .where('status', '==', 'published')
        .where('startDate', '<', toDay)
        .get();

    if (result.size === 0) {
        return null;
    }

    return result.docs.filter(r => toDay < r.data().endDate.toDate()).map(d => ({
        id: d.id,
        ...d.data()
    }))
}

export const signIn = ({ email, password }) => {
    return firebase.doSignInWithEmailAndPassword(email, password);
};

export const createApiUser = ({email, password}) => {
    return firebase.doCreateUserWithEmailAndPassword(email, password);
};

export const createDbUser = ({authUser, email, ...data}) => {
    // Create a user in your Firebase realtime database
    return firebase.user(authUser.user.uid).set(
        {
            email,
            // roles,
            ...data
        },
        { merge: true }
    );
};

export const updateApiUser = (values) => {
    return firebase.user(firebase.auth.currentUser.uid).set(values, { merge: true });
}

export const addCardToUser = (card) => {
    return firebase.user(firebase.auth.currentUser.uid).update({
        paymentMethod: firebase.firestore.FieldValue.arrayUnion(card)
    });
}

export const removeCardToUser = (card) => {
    return firebase.user(firebase.auth.currentUser.uid).update({
        paymentMethod: firebase.firestore.FieldValue.arrayRemove(card)
    });
}

export const setDefaultCardToUser = (cardId) => {
    return firebase.user(firebase.auth.currentUser.uid).update({
        defaultPaymentMethodId: cardId
    });
}

export const updateApiOrder = async (values) => {

    const response = firebase.db.collection('orders').doc(values.id).set(values, { merge: true });
    // update coupon's use count
    if (values.price.hasDiscount) {
        const increment = firebase.firestore.FieldValue.increment(1);
        await firebase.db.collection('coupons').doc(values.price.discountCoupon.id).set({used: increment}, { merge: true });
    }

    return response;
}

export const deleteApiOrderById = (id) => {
    return firebase.db.collection('orders').doc(id).delete();
}

export const fetchDbUser = (uid) => {
    return firebase.user(uid).get().then(snap=>snap.data()).then(userData => {
        if (userData.subscriptionRef) {
            return userData.subscriptionRef.get().then(res => {
                userData.subscription = res.data();
                return userData;
            });
        }
        return userData;
    });
};

export const getUserRef = () => firebase.user(firebase.auth.currentUser.uid).get();

export const resetPassword = (email) => {
    return firebase.doPasswordReset(email);
};

export const createApiOrder = async ({
    date,
    weekId,
    recipes: {
        recipesNumber,
        recipesSelection,
        price: recipesPrice,
    },
    grocery: {
        itemsSelection
    },
    delivery: {
        deliveryChoice,
        deliveryChoiceData,
        partnerDeliveryAddress,
        privateDeliveryAddress,
        price: deliveryPrice,
        deliveryHoursChoice,
        deliveryHoursChoiceData,
    },
    subscription : {
        isSubscription,
        subscriptionChoice,
        price: subscriptionPrice,
    },
    price: {
        totalOrder,
        hasDiscount,
        discountCoupon
    },
    isFirstOrder,
    isUpfrontPayment,
    paymentMethod,
    state,
}) => {
    // add ref to some elements
    const order = {
        date,
        weekId,
        recipes: {
            recipesNumber,
            recipesSelection,
            price: recipesPrice
            // recipesSelection: recipesSelection.map(selectionRef => firebase.db.collection('recipes').doc(selectionRef)), //.ref
        },
        grocery: {
            itemsSelection: itemsSelection,// itemsSelection.map(selectionRef => firebase.db.collection('groceries').doc(selectionRef)), //.ref
        },
        delivery: {
            deliveryChoice,
            deliveryChoiceData,
            // add ref to parner ?
            partnerDeliveryAddress,
            privateDeliveryAddress,
            price: deliveryPrice,
            deliveryHoursChoice,
            deliveryHoursChoiceData,
        },
        subscription : {
            isSubscription,
            subscriptionChoice,
            price: subscriptionPrice,
        },
        price: {
            totalOrder,
            hasDiscount,
            discountCoupon
        },
        isFirstOrder,
        isUpfrontPayment,
        paymentMethod,
        state,
    };
    const resp = await firebase.createApiOrder(order);
    // update coupon's use count
    if (hasDiscount) {
        const increment = firebase.firestore.FieldValue.increment(1);
        await firebase.db.collection('coupons').doc(discountCoupon.id).set({used: increment}, { merge: true });
    }
    return resp;
};

export const createApiSubscription = () => {
    const subscription = {
        isActive: true,
        suspendedWeekId: []
    };
    return firebase.createApiSubscription(subscription);
};

export const updateSubscriptionStatus = async (values) => {
    const userRef = firebase.user(firebase.auth.currentUser.uid);
    const subsRef = await userRef.get().then(snap => snap.data()).then(data=> data.subscriptionRef);
    console.log(subsRef)
    return firebase.db.collection('subscriptions').where('userRef', '==', userRef).get().then(snapshots => {
        console.log(snapshots);
        return snapshots.docs[0].ref
    })
        .then(result=> result.update({
            suspendedWeekId: firebase.firestore.FieldValue.arrayUnion(values)
        }))
        // .set(values, { merge: true });
}

export const updateSubscriptionStatusRemoveWeekId = (values) => {
    const userRef = firebase.user(firebase.auth.currentUser.uid);
    return firebase.db.collection('subscriptions').where('userRef', '==', userRef).get().then(snapshots => {
        console.log(snapshots);
        return snapshots.docs[0].ref
    })
        .then(result=> result.update({
            suspendedWeekId: firebase.firestore.FieldValue.arrayRemove(values)
        }))
        // .set(values, { merge: true });
}

export const updateSubscriptionDeactivate = () => {
    const userRef = firebase.user(firebase.auth.currentUser.uid);
    // const sub = userRef.get()
    return firebase.db.collection('subscriptions').where('userRef', '==', userRef).get().then(snapshots => {
        console.log(snapshots);
        return snapshots.docs[0].ref
    })
        .then(result=> result.update({
            isActive: false
        }))
};

export const updateSubscriptionReactivate = () => {
    const userRef = firebase.user(firebase.auth.currentUser.uid);
    return firebase.db.collection('subscriptions').where('userRef', '==', userRef).get().then(snapshots => {
        console.log(snapshots);
        return snapshots.docs[0].ref
    })
        .then(result=> result.update({
            isActive: true
        }))
};

export const getUserToHandle = () => {
    return firebase.user(firebase.auth.currentUser.uid);
};

export const getCurrentOrder = (weekToken) => {
    // return firebase.db.collection('orders').get().then(snap => snap.docs.map(d =>d.data()))
    const userRef = firebase.user(firebase.auth.currentUser.uid)
    return firebase.db.collection('orders').where('userRef', '==', userRef).where('weekId', '==', weekToken).get();
};

export const getOrder = () => {
    // return firebase.db.collection('orders').get().then(snap => snap.docs.map(d =>d.data()))
    const userRef = firebase.user(firebase.auth.currentUser.uid)
    return firebase.db.collection('orders').where('userRef', '==', userRef).get()
};

export const getOrders = () => {
    // return firebase.db.collection('orders').get().then(snap => snap.docs.map(d =>d.data()))
    const userRef = firebase.user(firebase.auth.currentUser.uid);
    return firebase.db.collection('orders').where('userRef', '==', userRef).orderBy('date', 'desc').get();
};

export const getWeekRecipes = (weekToken) => {
    if (typeof weekToken === 'undefined') {
        throw new Error('need valide weekToken');
    }
    /*
    let weekIdParams = weekId;
    if (getWeekId(getDate()) === weekId) {
        weekIdParams = '*';
    }
    */
    return firebase.db.collection('recipes').where('weekId', '==', weekToken).get()//.then(snapshot => snapshot.docs.map(doc => doc.data()));
        .then(snapshots => snapshots.docs.map(snap => ({
            id: snap.id,
            ...snap.data()
        })))
        .then(data => {
            if (data.length === 0) {
                return firebase.db.collection('recipes').where('weekId', '==', '*').get()
                    .then(snapshots => snapshots.docs.map(snap => ({
                        id: snap.id,
                        ...snap.data()
                    })))
            }
            return data;
        });
};

export const getPartners = () => {
    return firebase.db.collection('partners').get()
        .then(snapshots => snapshots.docs.map(snap => ({
            partnerId: snap.id,
            ...snap.data()
        })));
};

export const getGroceries = () => {
    const trimId = (id) => id.replace(/EP-/gi, '');
    return firebase.db.collection('groceries').where('isActive', '==', 'TRUE').get()
        .then(snapshots => snapshots.docs.sort((a, b) => trimId(a.id) - trimId(b.id)).map(snap => ({
            groceryId: snap.id,
            ...snap.data()
        })));
};

export const getMiniCabadici = () => {
    return firebase.db.collection('minis').where('isActive', '==', 'TRUE').get()
        .then(snapshots => snapshots.docs.map(snap => ({
            miniId: snap.id,
            type: 'mini',
            ...snap.data()
        })));
};

export const attachAuthHandler = (handler) => {
    if (typeof window !== 'undefined') {
        return firebase.auth.onIdTokenChanged(handler);
    }
};

export const getUser = () => {
    return firebase.auth.currentUser;
};

export const signOutFromApi = () => {
    return firebase.doSignOut();
};

export const addCustomerFromApi = ({email, token, name, phone, meta}) => {
    const addCustomer = firebase.functions.httpsCallable('stripe-createUserStripe');
    return addCustomer({email, token, name, metadata: meta, phone});
};

export const addCustomerToAuthClaimsFromApi = ({email}) => {
    const addCustomer = firebase.functions.httpsCallable('addCustomer');
    return addCustomer({email});
};

export const makePaymentFromApi = (order, id) => {
    const makePayment = firebase.functions.httpsCallable('stripe');
    return makePayment(order, id);
};

export const addChargeToCustomerFromApi = (charge) => {
    const addCharge = firebase.functions.httpsCallable('stripe-addStripeChargeToCustomer');
    return addCharge(charge);
};

export const createCard = (values) => {
    const createCardStripe = firebase.functions.httpsCallable('stripe-createCardStripe');
    return createCardStripe(values);
};

export const updateStripeDefaultCard = (values) => {
    const updateDefaultCardStripe = firebase.functions.httpsCallable('stripe-updateDefaultCardStripe');
    return updateDefaultCardStripe(values);
};

export const deleteCard = (values) => {
    const deleteCardStripe = firebase.functions.httpsCallable('stripe-deleteCardStripe');
    return deleteCardStripe(values);
};

export const logInformation = () => {
    const logInformation = firebase.functions.httpsCallable('logInformation');
    return logInformation({tutu: 'tutu'});
};

export const checkCoupon = coupon => {
    const checkCoupon = firebase.functions.httpsCallable('checkCoupon');
    return checkCoupon({coupon});
};
