import React from 'react'
import { createGlobalStyle, ThemeProvider, withTheme } from 'styled-components';

export const theme = {
  font: {
      main: 'Avenir Next',
      title: 'Offerings Regular',
      special: 'Sophistica',
  },
  color: {
      red: 'rgb(132, 42 , 45)',
      /* blanc opacité 40% */

      /* subheader, coup de coeur, barre le concept */
      beigeFort: '#decab2',
      /* backgroundLivraison, backround concept */
      beigeFaible: '#f2eee7',
      /* en savoir plus, poisson, livraison */
      jauneFort: '#f3bc41',
      /* bande 3 SOLUTIONS possibles */
      jauneFaible: '#efd9a6',
      /* se connecter, pointSlider, chevrons, Viande, enSavoirplus, Footer */
      rougeFort: '#a93b3b',
      /* contenuCabadici, slider, LES REcettes, cuisson lente, livré le, ensavoirplus, */
      rougeFaible: '#de5b58',
      rougeTresFaible: '#F1E3E2',
      /* prixConcept, Végé, en savoir plus */
      vertFort: '#97a553',
      /* bandeau entre recette et livraison, entre l'idée et commander */
      vertFaible: '#c2c7ab',
      noirFort: '#000',
      noirFaible: '#606060',

  }
};

const withThemeThemed = WrappedComponent => {
    return props => <WrappedComponent {...props} theme={theme} />
}

export default withThemeThemed
