import thunk from 'redux-thunk';
import { createStore as reduxCreateStore, applyMiddleware } from 'redux';
// import redu, { reducers } from './reducer';
import { reducers } from './reducers';

/*
const initialState = {
    name: 'bebs',
    orders: [],
    WeekRecipes: [],
    MonthRecipe: {},
};
*/

const createStore = () => reduxCreateStore(
    reducers,
    // {recipesNumber: 5},
    applyMiddleware(thunk)
);

export default createStore;
