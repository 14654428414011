/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'core-js/modules/es6.set';
import 'core-js/modules/es6.map';
import 'raf/polyfill';

import 'core-js/modules/es7.array.includes'
import 'core-js/modules/es6.string.starts-with'
import 'core-js/modules/es7.object.entries'
import 'core-js/modules/es6.array.find-index'

// You can delete this file if you're not using it

import './index.css';


import React from 'react'
import { Provider } from 'react-redux'
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import {Helmet} from "react-helmet";

import SEO from './src/components1/SEO';
import * as fonts from './src/fonts';
import { store } from './src/stores'
import { theme } from './src/theme'
import './src/assets/icons';

import { attachAuthHandler } from './src/services';
import { updateAuthUserData } from './src/actions';


const GlobalStyle = createGlobalStyle`
    body {
        overflow-x: hidden;
    }

@media (min-width < 500px) {
  .react-tabs__tab-list {
      display: block;
      overflow-y: auto;
      width: 100%;
      white-space: nowrap;
  }
}

        @font-face {
            font-family: 'Sophistica';
            src: url(${fonts.Sophistica1TTF}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: 'Offerings Regular';
            src: url(${fonts.OfferingsRegularOTF}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: 'Avenir Next';
            src: url(${fonts.AvenirNextTTF}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        /*
        @font-face {
            font-family: 'Avenir Next';
            src: url(${fonts.AvenirNextTTC}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        */

        /* not used */
        @font-face {
            font-family: 'Offerings Regular Italic';
            src: url(${fonts.OfferingsRegularItalicOTF}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }


    /* Ripple effect */
    .ripple {
        background-position: center;
        transition: background 0.8s;
    }
    .ripple:hover {
        background: ${props => props.theme.color.vertFaible} radial-gradient(circle, transparent 1%, ${props => props.theme.color.vertFaible} 1%) center/15000%;
    }
    .ripple:active {
        background-color: ${props => props.theme.color.vertFort};
        background-size: 100%;
        transition: background 0s;
    }

    select:required:invalid {
        color: #d9d9d9;
    }
`;

// eslint-disable-next-line react/display-name,react/prop-types
export const wrapRootElement = ({ element }) => {
    attachAuthHandler(user => {
        if (user) {
            user.getIdTokenResult()
                .then(idTokenResult => {
                    // merge isCustomer field
                    if (idTokenResult.claims.isCustomer) {
                        return store.dispatch(updateAuthUserData({...user, isCustomer: true}));
                    }
                    return Promise.reject();
                })
                .catch(() => {
                    // alert('NOT CUSTOMER')
                    return store.dispatch(updateAuthUserData(user));
                })
        }
    });

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <>
                    <Helmet>
                        <script src="https://js.stripe.com/v3/" />
                    </Helmet>
                    <SEO title="CABADICI - Cuisinez sans y penser" />
                    <GlobalStyle />
                    {
                        element
                    }
                </>
            </ThemeProvider>
        </Provider>
    )
};


// https://www.gatsbyjs.org/packages/gatsby-background-image/
export const onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (typeof window.IntersectionObserver === `undefined`) {
        import(`intersection-observer`)
        console.log(`# IntersectionObserver is polyfilled!`)
    }
}
